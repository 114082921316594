import React, {Fragment, useState} from 'react'
import {graphql} from 'gatsby'
import {Container, Row, Col} from 'reactstrap'
import { FiLoader } from "react-icons/fi";
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import Layout from "../../containers/layout/layout"
import Header from '../../containers/layout/header'
import Footer from '../../containers/layout/footer'
import Heading from '../../components/shared/heading'
import Text from '../../components/shared/text'
import Social from '../../components/socials/layout-two'
import Blog from '../../components/blog/layout-two'
import Button from '../../components/shared/button'
import {AuthorSection, AuthorImg, AuthorInfo, AuthorBlogs, LoadMoreBtnWrap} from './author-template.stc'

const AuthorTemplate = ({ data, pageContext, location, ...restProps }) => {
    const [author] = data.allContentfulPerson.edges;
    const {
        authorCSS: {nameHeadingCSS, nameTextCSS, taglineCSS, bioCSS, socialCSS},
        blogSectionCSS: {titleCSS, blogCSS},
        loadMoreBtn
    } = restProps;

    const { name, tagline, image } = author.node;
    const bio = author.node.shortBio.shortBio;
    const {twitter, facebook, instagram, linkedin, youtube, github} = author.node;
    const social = {
        twitter: twitter,
        facebook: facebook,
        instagram: instagram,
        linkedin: linkedin,
        youtube: youtube,
        github: github,
    };

    let posts = data.allContentfulBlogPost.edges.map( ({ node }) => {
        const dateSplit = node.date.split(" ");
        const {text: quote_text, author: quote_author} = node.quote !== null ? node.quote : { };
        
        return {
            node: {
                fields: {
                    slug: node.slug,
                    collection: 'contentfulPosts',
                    dateSlug: node.dateSlug
                },
                frontmatter: {
                    sortKey: node.sortKey,
                    date: node.date,
                    format: node.format,
                    title: node.title,
                    video_link: node.video_link,
                    quote_text: quote_text,
                    quote_author: quote_author,
                    link: node.link,
                    image: node.image,
                    images: node.images,
                    category: node.category,
                },
                excerpt: node.body.childMarkdownRemark.excerpt,
            }
        }
    });
    const totalCount = data.allContentfulBlogPost.totalCount;

    const initialBlogs = posts.splice(0, 8);
    const [hasMore, setHasMore] = useState(true);
    const [currentShow, setCurrentShow] = useState(8)
    const [nextToShow, setNextToShow] = useState([])
    const [loading, setLoading] = useState(false);
    const blogWillShow = () => {
        setLoading(true)
        setTimeout(() => {
            const nextBlogs = posts.splice(currentShow, 2);
            setCurrentShow(prev => prev + 2)
            setNextToShow([...nextToShow, ...nextBlogs])
            setLoading(false)
        }, 3000)
    }
    const loadMoreHandler = () => {
        blogWillShow()
        hasMoreToLoad()
    }

    const hasMoreToLoad = () => {
        const blogsToLoad = posts.length - currentShow;
        setHasMore(blogsToLoad === 0 ? false : true)
    }

    return (
        <Layout>
            <SEO 
                title={`Author: ${author?.name}`}
                description={author?.bio}
                keywords={['author', 'author details', 'posts by author']}
            />
            <Header authorData={author.node}/>
            <div className="main-content">
                <AuthorSection>
                    <Container>
                        <Row className="align-items-center">
                            <Col sm={4} lg={{size: 3, offset: 1}}>
                                <AuthorImg>
                                    <Img fixed={image.fixed} alt={name}/>
                                </AuthorImg>
                            </Col>
                            <Col sm={8} lg={7}>
                                <AuthorInfo>
                                    {name && <Heading {...nameHeadingCSS}>{name}</Heading>}
                                    {tagline && <Heading {...taglineCSS}>{tagline}</Heading>}
                                    {bio && <Text {...bioCSS}>{bio}</Text>}
                                    {social && <Social {...socialCSS} social={social}/>}
                                </AuthorInfo>
                            </Col>
                        </Row>
                    </Container>
                </AuthorSection>
                <AuthorBlogs>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Text>Total Post ({totalCount})</Text>
                                <Heading {...titleCSS}>Articles By This Author</Heading>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Fragment>
                                    {initialBlogs.map(blog => (
                                        <Blog
                                            {...blogCSS}
                                            key={blog.node.fields.slug}
                                            content={{
                                                ...blog.node.fields, 
                                                ...blog.node.frontmatter,
                                                excerpt: blog.node.excerpt
                                            }}
                                        />
                                    ))}
                                    {nextToShow && nextToShow.map(blog => (
                                        <Blog
                                            {...blogCSS}
                                            key={blog.node.fields.slug}
                                            content={{
                                                ...blog.node.fields, 
                                                ...blog.node.frontmatter,
                                                excerpt: blog.node.excerpt
                                            }}
                                        />
                                    ))}
                                </Fragment>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className="text-center">
                                {hasMore && (
                                    <LoadMoreBtnWrap isLoading={loading}>
                                        <Button 
                                            disabled={!hasMore} 
                                            onClick={loadMoreHandler} 
                                            {...loadMoreBtn} 
                                            icon={<FiLoader/>}>Load More Article</Button>
                                    </LoadMoreBtnWrap>
                                )}
                                {!hasMore && <p>There is no more posts to show.</p>}
                            </Col>
                        </Row>
                    </Container>
                </AuthorBlogs>
            </div>
            <Footer/>
        </Layout>
    )
}

AuthorTemplate.defaultProps = {
    authorCSS: {
        nameHeadingCSS: {
            as: "h2",
            fontSize: ["28px", "32px", "36px"],
            fontWeight: 700,
            color: "#fff",
            lineHeight: 1.1,
            
        },
        nameTextCSS: {
            as: 'span',
            fontWeight: 300
        },
        taglineCSS: {
            as: 'h5',
            color: "#fff",
            mb: ['15px', null, '24px']
        },
        bioCSS: {
            fontSize: '16px',
            color: '#fff',
            fontWeight: 300,
            mb: ['23px', null, '33px']
        },
        socialCSS: {
            color: '#fff',
            borderColor: '#fff'
        }
    },
    blogSectionCSS: {
        titleCSS: {
            as: 'h4',
            color: 'secondaryColor',
            fontWeight: 700,
            mb: '41px',
            mt: '10px'
        },
        blogCSS: {
            mb: '30px'
        }
    },
    loadMoreBtn: {
        btnsize: 'large',
        varient: 'contained',
        shape: 'rounded',
        fontSize: ['12px', null, null, '13px'],
        letterSpacing: [0, null, null, '1px'],
        iconcss: {
            pl: '8px',
            width: '14px',
            height: '12px'
        }
    }
}
  
export const query = graphql `
    query BlogByAuthorQuery($author: String!){
        allContentfulBlogPost(
            filter: { 
                author: {
                    authorId: {eq: $author}
                }
            }
            sort: {order: DESC, fields: publishDate}
        ) {
            totalCount
            edges {
                node {
                    ...MajorContentfulBlogPostField
                    body {
                        childMarkdownRemark {
                            excerpt(format: PLAIN, pruneLength: 165)
                        }
                    }
                }
            }
        }
        allContentfulPerson(filter: { authorId: {eq: $author} }) {
            edges {
                node {
                    name
                    tagline
                    shortBio {
                        shortBio
                    }
                    title
                    image {
                        fixed(width: 290, height: 290, quality: 100) {
                            ...GatsbyContentfulFixed_withWebp
                        }
                    }
                    facebook
                    twitter
                    instagram
                    linkedin
                    youtube
                    github
                }
            }
        }
    }
`;

export default AuthorTemplate
 